/* General content container */
.content1 {
  margin-top: 10%;
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Flexbox layout for the sections */
.bangle-container, .rings, .necklace-container, .mangalsutra, .toe-rings-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 20px;
}

/* Text section styling */
.bangle-text, .rings-text, .necklace-text, .mangal-text, .toe-ring-text {
  flex: 1;
  text-align: left;
  font-size: 16px;
  line-height: 1.6;
  padding: 10px;
}

/* Image styling */
.bangle-image, .rings-image, .necklace-image, .mangal-images, .toe-ring-image {
  flex: 1;
  max-width: 300px;
  height: auto;
  margin-left: 20px;
  transition: 1s ease;
}
.necklace-image{
  transition: 1s ease;
}
.necklace-image:hover{
  -webkit-transform: scale(0.8);
-ms-transform: scale(0.8);
transform: scale(0.8);
transition: 1s ease;
}
/* Image hover effects */
.bangle-image:hover, .rings-image:hover, .toe-ring-image:hover, .mangal-images:hover {
  transform: scale(1.1);
  transition: 1s ease;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .content1{
    margin-top: 30%;
  }
  /* Stack content vertically on mobile view */
  .bangle-container, .rings, .necklace-container, .mangalsutra, .toe-rings-container {
    margin-top: 10%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-bottom: 15px;
  }

  /* Center text and adjust padding */
  .bangle-text, .rings-text, .necklace-text, .mangal-text, .toe-ring-text {
    text-align: center;
    padding: 5px;
    font-size: 14px;
  }

  /* Make images responsive */
  .bangle-image, .rings-image, .necklace-image, .mangal-images, .toe-ring-image {
    max-width: 100%;
    height: auto;
    margin: 0 auto 10px auto;
  }

  /* Adjust heading sizes */
  h1 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }
}
