/* General Styles */
.paymentContainer {
  display: grid;
  place-items: center;
  background-color: #F0EDE6;
  min-height: 100vh; /* Ensure the container takes full height */
  padding: 2vmax; /* Add some padding around */
}

.paymentForm {
  width: 100%; /* Ensure it scales properly */
  max-width: 600px; /* Prevent it from getting too wide */
  background-color: #EBE4DE;
  padding: 2vmax; /* Add some padding inside the form */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */
  border-radius: 8px; /* Optional: Rounded corners for aesthetics */
}

.paymentForm > p {
  font: 400 2vmax "Roboto";
  color: rgba(0, 0, 0, 0.753);
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  padding: 1vmax 0;
  text-align: center;
  margin: 0; /* Remove margin to avoid extra spacing */
}

.paymentForm > div {
  display: flex;
  align-items: center;
  margin: 2vmax 0;
  position: relative; /* Position relative to position the icon */
}

.paymentInput {
  padding: 1vmax 4vmax;
  width: 100%; /* Allow the input to use full width */
  max-width: 400px; /* Ensure it doesn't get too wide */
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  outline: none;
}

.paymentForm > div > svg {
  position: absolute;
  left: 10px; /* Adjust the positioning as needed */
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

.paymentFormBtn {
  border: none;
  background-color: #000000;
  color: white;
  font: 300 0.9vmax "Roboto";
  width: 100%;
  padding: 0.8vmax;
  cursor: pointer;
  transition: background-color 0.5s;
  outline: none;
  border-radius: 4px; /* Rounded corners for the button */
}

.paymentFormBtn:hover {
  background-color: #524f40;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .paymentForm {
    width: 90%; /* Adjust width for larger tablets and small screens */
  }

  .paymentForm > p {
    font: 400 1.5vmax "Roboto"; /* Smaller font size for medium screens */
    padding: 1.5vmax 0; /* Adjust padding */
  }

  .paymentForm > div {
    margin: 1.5vmax 0; /* Adjust margin */
  }

  .paymentInput {
    padding: 1.5vmax 3vmax; /* Adjust padding */
  }

  .paymentForm > div > svg {
    font-size: 1.5vmax; /* Adjust icon size */
  }

  .paymentFormBtn {
    font: 300 1vmax "Roboto"; /* Adjust font size */
    padding: 1vmax; /* Adjust padding */
  }
}

@media screen and (max-width: 600px) {
  .paymentForm {
    width: 95%; /* Adjust width for small screens */
  }

  .paymentForm > p {
    font: 400 4vw "Roboto"; /* Larger font size for small screens */
    padding: 2vw 0; /* Adjust padding */
  }

  .paymentForm > div {
    margin: 2vw 0; /* Adjust margin */
  }

  .paymentInput {
    padding: 2vw 5vw; /* Adjust padding */
  }

  .paymentForm > div > svg {
    font-size: 4vw; /* Adjust icon size */
  }

  .paymentFormBtn {
    font: 300 2vw "Roboto"; /* Adjust font size */
    padding: 2vw; /* Adjust padding */
  }
}
