.rguide{
    margin-top: 10%;
    margin-left: 3%;
}

.ring-size-guide {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    /* Light background for the container */
  }
  
  table {
    width: 30%; /* Reduce the width of the table */
    border-collapse: collapse;
    margin-left: -70%;
    font-size: 14px; /* Font size for web view */
    text-align: left;
 /* Light background for the table */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for table */
  }
  
  table thead th {
    background-color: powderblue; /* Light blue header background */
    padding: 8px; /* Padding for table headers */
    font-weight: bold;
    border-bottom: 2px solid #ddd;
  }
  
  table tbody td {
    padding: 8px; /* Padding for table body */
    border-bottom: 1px solid #ddd;
    text-align: center;
  }
  
  table tr:nth-child(even) {
    background-color: #f1f8e9; /* Light green for alternating rows */
  }
  
  /* Mobile View */
  @media (max-width: 600px) {

    .rguide {
        margin-top: 40%;
        margin-left: 5%;
    }

    table {
        margin-left: 0; /* Center table in mobile view */
        width: 90%; /* Adjust table width for mobile view */
        font-size: 12px; /* Slightly smaller font size */
    }

    table thead th {
        padding: 6px; /* Slightly smaller padding for mobile */
        text-align: center; /* Center align headers in mobile */
    }

    table tbody td {
        padding: 6px; /* Slightly smaller padding for mobile */
        text-align: center; /* Center align table values in mobile */
    }

    table tbody tr {
        display: table-row; /* Ensure table rows display as rows in mobile */
        justify-content: center; /* Ensure table values remain centered */
    }

    table thead th, table tbody td {
        display: table-cell; /* Ensure table structure remains consistent */
        text-align: center; /* Keep the alignment centered */
    }
}