/* Base styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* .banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height:500px; /* Adjust height as necessary 
  color: #fff; /* Text color for contrast 
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3); 
}  */

body, html {

  height: 100%;
  font-family: 'Roboto', sans-serif;
  background-color: #F0EDE6;
}
.banner-slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height:100%;
  overflow: hidden; /* Prevent images from spilling outside */
  margin: 0.5rem auto;
  padding: 0rem;
}
.banner-slider img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the images fill the container without distortion */
  display: block;
  
}
.categories-row {
  display: flex;
  justify-content: center;
  background-color: #f8f8f8;
  padding: 10px 0;
}

.categories-list {
  display: flex;
  list-style-type: none;
  gap: 20px;
  margin-top: 7rem;
}

.categories-list li {
  font-size: 16px;
  font-weight: bold;
}

.categories-list li a {
  text-decoration: none;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.categories-list li a:hover {
  background-color: #e0e0e0;
}
@media (max-width: 768px) {
  .categories-list {
    margin-top: 7rem;
    padding-top: 20px;
    padding-left: 10px;
    gap: 5px; /* Reduces the gap between items */
  }
  
  .categories-list li {
    font-size: 14px; /* Adjust font size for better spacing */
  }
}

@media (max-width: 480px) {
  .categories-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates two equal columns */
    gap: 10px; /* Adjust gap between the items */
    margin-top: 50px;
    padding-top: 30px;
    margin-top: 6rem;
  }

  .categories-list li {
    text-align: center; /* Aligns the text in the center */
    font-size: 14px; /* Adjusts font size */
  }

}

/* Banner styles */
/* Home.css */

/* Banner styles */
/* Home.css */



/* .banner-1 {
  margin-bottom: 0px;
  z-index: 1; 
}



.banner h1 {
  font-size:1.5rem; 
  margin-bottom: 0px;
}

.banner button {
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  color: #fbf5f5;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.banner button:hover {
  background-color: #b86161;
} */



/* Slider styles */
.featuredProducts {
  background-color: #ffffff;
  margin: 2rem auto;
  padding: 2rem;
}

.homeHeading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container1 {
  display: flex;
  margin: 1rem auto;
  width: 100%;
  justify-content: center;
}
/* Slider styles */
.slick-slider {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.slick-list {
  display: flex;
  transition: transform 0.5s ease;
}

.slick-slide {
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
}

.slick-prev,
.slick-next {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

/* Slick dots styles */
.slick-dots {
  position: absolute;
  bottom: -15px;
  display: flex !important;
  justify-content: center;
  width: 100%;
  padding: 10px;
  list-style: none;
}

.slick-dots li {
  margin: 0 5px;
}

.slick-dots li button {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}

.slick-dots li.slick-active button {
  background: rgb(15, 15, 15);
}

/* Responsive styles */
@media (max-width: 768px) {
  .banner-slider img {
    height: auto;        /* Reduce height for smaller screens */
  }

  .homeHeading {
    font-size: 1.2rem;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (max-width: 480px) {

  .homeHeading {
    font-size: 0.8rem;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.Categories-container {
  background-color: #FFF5F5; /* Equivalent to bg-red-50 */
  margin: 2rem auto; /* Equivalent to mt-12 */
  padding: 2rem; /* Added padding for better spacing */
}

.Categories {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.header-section {
  text-align: center;
  margin-bottom: 2.5rem; /* Equivalent to mb-10 */
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 2rem; /* Equivalent to text-3xl */
  font-weight: bold;
}

.body-section {
  display: flex;
  justify-content: center;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px,1fr)); 
  gap: 20px; /* Equivalent to place-items-center */

  /* Media queries to handle responsiveness */
  @media (min-width: 640px) {
    grid-template-columns: repeat(3, 1fr); /* Equivalent to sm:grid-cols-3 */
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr); /* Equivalent to md:grid-cols-4 */
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(5, 1fr); /* Equivalent to lg:grid-cols-5 */
  }
}

.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem; /* Equivalent to space-y-3 */
}

.product-image {
  height: 300px; /* Equivalent to h-[300px] */
  width: 220px; /* Equivalent to w-[220px] */
  object-fit: cover;
  border-radius: 0.375rem; /* Equivalent to rounded-md */
}

.product-title {
  font-weight: 600; /* Equivalent to font-semibold */
}

/* Ribbon1.css */
.ribboncontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.ribbon-container1,
.ribbon-container2 {
  flex: 1;
  min-width: 300px;
  max-width: 500px;
  padding: 20px;
}

.ribbon-title {
  font-size: 2.25rem; /* 4xl */
  font-weight: bold;
  padding: 24px 24px; /* px-6 py-4 */
}

.ribbon-description {
  font-size: 1.5rem; /* 2xl */
  color: #6b7280; /* gray-500 */
  letter-spacing: wide;
  line-height: 1.75rem; /* leading-7 */
  padding: 0 24px; /* px-6 */
}

/* Style for the image */
.ribbon-container2 img {
  width: 80%;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .ribbon-title {
    font-size: 2rem; /* 3xl */
    padding: 20px 20px; /* Adjusted padding */
  }

  .ribbon-description {
    font-size: 1.25rem; /* xl */
    line-height: 1.5rem; /* Adjusted line height */
  }
}

@media (max-width: 768px) {
  .ribbon-title {
    font-size: 1.75rem; /* Adjusted size */
    padding: 16px 16px; /* Adjusted padding */
  }

  .ribbon-description {
    font-size: 1rem; /* lg */
    line-height: 1.25rem; /* Adjusted line height */
  }
}

@media (max-width: 480px) {
  .ribbon-title {
    font-size: 1.5rem; /* Adjusted size */
    padding: 12px 16px; /* Adjusted padding */
    padding-left: 25px;
  }

  .ribbon-description {
    font-size: 0.875rem; /* base */
    line-height: 1rem; /* Adjusted line height */
  }
  .ribboncontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 30px 20px;
    padding-left: 50px;
    padding-right: 50px;
  }

  
}

/*featured products */
/* Featured Products Grid Layout */
.featuredProducts {
  background-color: #ffffff;
  margin: 1rem auto;
  padding: 1rem;
}

.featuredProducts .product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)); /* Ensures flexibility and responsiveness */
  gap: 20px; /* Adjusts the space between products */
  margin-top: 20px;
}

.featuredProducts .product-card {
  border: 1px solid #e0e0e0;
  padding: 10px;
  text-align: center;
  transition: box-shadow 0.3s ease-in-out;
}

.featuredProducts .product-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive grid adjustments */
@media (min-width: 640px) {
  .featuredProducts .product-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 products per row on small screens */
  }
}

@media (min-width: 768px) {
  .featuredProducts .product-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 products per row on medium screens */
  }
}

@media (min-width: 1024px) {
  .featuredProducts .product-grid {
    grid-template-columns: repeat(4, 1fr); /* 4 products per row on large screens */
  }
}

@media (min-width: 1280px) {
  .featuredProducts .product-grid {
    grid-template-columns: repeat(5, 1fr); /* 5 products per row on extra-large screens */
  }
}

/* Adjust the heading and container to ensure consistency */
.featuredProducts .homeHeading {
  text-align: center;
  margin-bottom: .25rem;
}

.featuredProducts .container1 {
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}
.explore-btn{
  z-index: 2;
  cursor: pointer;
  background-color: #f1f4ed; 
  letter-spacing: 3px;
  text-transform: uppercase;
  border-radius: 20px; 
  padding: 1rem 2rem;
  font-size: 1rem;
  color: #1e1e1e;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.explore-btn:hover{
  transform: translateY(-5px) scale(1.05); /* 3D hover effect */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
}