.questions{
    margin-left: 3%;
    
}

.questions h2{
    text-align: left;
    margin-top: 13%;
}

@media screen and (max-width: 600px) {
    .questions h2 { 
      margin-top: 45%; /* Adjust this value to control how far down the heading should appear */
    }
    .questions{
        margin-left: 7%;
    }
  }
  