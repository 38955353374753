body{
  margin-top: 2.5%;
}

.ProductDetails {
  background-color: rgb(255, 255, 255);
  width: 100vw;
  max-width: 100%;
  padding: 6vmax;
  box-sizing: border-box;
  display: flex;
}

.share{
  margin-left: 80%;
  position: relative;
  display: flex;
  padding: 10px;   
}

.addbtn button{ 
  border: none;
  background-color: #000000;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
 
  padding: 12px 18px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  outline: none;
  color: white;
  margin-right: 10vw;
  height: auto;
  position: relative;
  right: 0;
  bottom: -1.4vw;
}


.addbtn2 button{ 
  border: none;
  background-color: #000000;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-left: 13vw;
  padding: 12px 23px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  outline: none;
  color: white;
  display: block;
  height: auto;
  position: relative;
  bottom:1.3vw;
 
}  
.addbtn button:hover,.addbtn2 button:hover{
  background-color: #524f40;
}
 /* .addbtn{
  border: none;
  cursor: pointer;
  color: #9B9A94;
  transition: all 0.5s;
  background-color: #9B9A94;
  font: 500 0.7vmax "Roboto";
  border-radius: 5px;
  padding: 0.5vmax 2vmax;
  margin: 1vmax;
  outline: none;
  }*/


.ProductDetails > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 2vmax;
  box-sizing: border-box;
  border: 1px solid white;
}

.quant{
  width:100px
}
.ProductDetails > div:last-child {
  align-items: flex-start;
}

.CarouselImage {
  width: 20vmax;
}

.detailsBlock-1 > h2 {
  display: flex;
  justify-content: flex-start;
  color: rgb(10, 10, 10);
  font: 600 1.6vmax "Roboto";
}

.App-header .heart{
  width: 48px;
  height: 48px;
  padding: 12px;
  top: 12px;
  right: 12px;
  border: none;
  margin-left:20%;
}
.App-header .heart:hover{
  background-color: #e0cbc4;
}
.App-header .share{
  width: 88px;
  height: 48px;
  padding: 12px;
  top: 12px;
  right: 12px;
  border: none;
  margin-left:20%;
}

/*
 background-color: purple;
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
    font-family: monospace;
    color: white;
    padding: 20px;
    margin-left: 10px;
    text-align: center;
    cursor: pointer;*/



.detailsBlock-1 > p {
  color: rgba(54, 54, 54, 0.582);
  font: 15px cursive;
}

.detailsBlock-2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.205);
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
  width: 70%;
  padding: 1vmax 0;
}

.detailsBlock-2-span {
  font: 200 0.8vmax cursive;
  color: rgba(0, 0, 0, 0.699);
}

.detailsBlock-3 {
  width: 70%;
}

.detailsBlock-3 > h1 {
  color: rgba(17, 17, 17, 0.795);
  font: 400 1.8vmax "Franklin Gothic Medium";
  margin: 1vmax 0;
}
.detailsBlock-3-1 {
  display: flex;
  align-items: center;
}

.detailsBlock-3-1-1 > button {
  border: none;
  background-color: rgba(0, 0, 0, 0.616);
  padding: 0.5vmax;
  cursor: pointer;
  color: white;
  transition: all 0.5s;
}
.detailsBlock-3-1-1 > button:hover {
  background-color: rgba(0, 0, 0, 0.767);
}

.detailsBlock-3-1-1 > input {
  z-index: 99999;
  border: none;
  padding: 0.5vmax;
  width: 50px;
  text-align: center;
  outline: none;
  color: rgba(0, 0, 0, 0.74);
}

.detailsBlock-3-1 > button:last-child {
  border: none;
  cursor: pointer;
  color: white;
  transition: all 0.5s;
  background-color: #9B9A94;
  font: 500 0.7vmax "Roboto";
  border-radius: 20px;
  padding: 0.5vmax 2vmax;
  margin: 1vmax;
  outline: none;
}

.detailsBlock-3-1 > button:last-child:hover {
  background-color: #000000;
}

.detailsBlock-3 > p {
  border-top: 1px solid rgba(0, 0, 0, 0.205);
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
  padding: 1vmax 0;
  color: rgba(0, 0, 0, 0.651);
  font: 400 1vmax "Roboto";
  margin: 1vmax 0;
}

.detailsBlock-4 {
  color: rgba(0, 0, 0, 0.897);
  font: 1000 1.2vmax sans-serif;
}

.detailsBlock-4 > p {
  color: rgba(0, 0, 0, 0.534);
  font:  15px sans-serif;
}

.submitReview {
  border: none;
  background-color: #000000;
  font: 500 1rem "Roboto";
  border-radius: 5px;
  padding: 0.6vmax 2vmax;
  margin: 1vmax 0;
  color: white;
  cursor: pointer;
  transition: all 0.5s;
  outline: none;
}
.submitReview:hover {
  background-color: #524f40;
  transform: scale(1.1);
}

.submitDialog {
  display: flex;
  flex-direction: column;
}
.submitDialogTextArea {
  border: 1px solid rgba(0, 0, 0, 0.082);
  margin: 1vmax 0;
  outline: none;
  padding: 1rem;
  font: 300 1rem "Roboto";
}

.reviewsHeading {
  color: #000000be;
  font: 500 1.4vmax "Roboto";
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.226);
  padding: 1vmax;
  width: 20vmax;
  margin: auto;
  margin-bottom: 4vmax;
}
.reviews {
  display: flex;
  overflow: auto;
}

.reviewCard {
  flex: none;

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.226);
  border: 1px solid rgba(56, 56, 56, 0.116);
  width: 30vmax;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1vmax;
  padding: 3vmax;
}
.reviewCard > img {
  width: 5vmax;
}
.reviewCard > p {
  color: rgba(0, 0, 0, 0.836);
  font: 600 0.9vmax "Roboto";
}
.reviewCardComment {
  color: rgba(0, 0, 0, 0.445);
  font: 300 0.8vmax cursive;
}

.noReviews {
  font: 400 1.3vmax "Gill Sans";
  text-align: center;
  color: rgba(0, 0, 0, 0.548);
}

@media screen and (max-width: 600px) {
  .ProductDetails {
    flex-direction: column;
    height: unset;
  }
  .quant{
    width: 300px;
  }

  .ProductDetails > div:last-child {
    align-items: center;
  }

  .detailsBlock-1 > h2 {
    font-size: 2.8vmax;
    text-align: center;
  }

  .detailsBlock-1 > p {
    text-align: center;
    font-size: 1vmax;
  }

  .detailsBlock-2 {
    justify-content: center;
  }
  .detailsBlock-2 > span {
    font-size: 1.5vmax;
  }

  .detailsBlock-3 > h1 {
    font: 700 3vmax "Franklin Gothic Medium";
    text-align: center;
  }

  .detailsBlock-3-1 {
    flex-direction: column;
  }

  .detailsBlock-3-1-1 {
    padding: 2vmax 0;
  }
  .detailsBlock-3-1-1 > button {
    padding: 1vmax;
    width: 3vmax;
  }

  .detailsBlock-3-1-1 > input {
    padding: 1.5vmax;
    width: 4vmax;
    font: 400 1.8vmax "Roboto";
  }

  .detailsBlock-3-1 > button:last-child {
    font: 500 1.7vmax "Roboto";
    padding: 1.5vmax;
    width: 20vmax;
    margin: 3vmax 0;
  }

  .detailsBlock-3 > p {
    padding: 2.5vmax 0;
    text-align: center;
    font: 400 2vmax "Roboto";
  }

  .detailsBlock-4 {
    font: 500 2.5vmax sans-serif;
  }

  .detailsBlock-4 > p {
    font: 300 1.8vmax sans-serif;
  }

  .submitReview {
    font: 500 1.7vmax "Roboto";
    padding: 1.5vmax;
    width: 20vmax;
    margin: 3vmax 0;
  }

  .reviewCard > p {
    font: 600 3vw "Roboto";
  }
  .reviewCardComment {
    font: 300 5vw cursive;
  }

  .addbtn button{
    font: 500 1.7vmax "Roboto";
    padding: 1.5vmax;
    width: 10vmax;
    margin: 3vmax 0vmax;
    margin-right: 30vw ;
    top:52%;
  left: 25%;
  }
  .addbtn2 button{
    font: 500 1.7vmax "Roboto";
    padding: 1.5vmax;
    width: 10vmax;
    margin: 3vmax 0vmax;
   
    top:52%;
  left: 25%;
  }
 
}

.App {
  text-align: center;
  margin-top: 50px;
}

.popup {
  position: absolute;
  margin-top: 30%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(214, 202, 202);
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.close {
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.copy-success {
  color: green;
  margin-top: 10px;
}

.popup ul {
  list-style: none;
  padding: 0;
}

.popup li {
  margin: 10px 0;
}

.popup a {
  color: #007bff;
  text-decoration: none;
}

.popup button {
  background: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}
/* Main content styling */
.App {
  text-align: center;
}

.App-header {
  margin-top: 50px;
  padding-left: 20px;
}
.heart{
  margin: 10px;
}
/* Modal styling */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
}

/* Modal content box */
.modal-content {
  background-color: #e9dada;
  padding: 20px;
  /*border: 1px solid #888;*/
  width: 80%;
  max-width: 500px;
  text-align: center;
  position: relative; /* for close icon */
}

.App-header{
  border-radius: 50%;
}
.App-header:hover{
  color: #007bff;
}

/* Close button */
.close {
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Share buttons */
.share-buttons {
  margin-top: 20px;
}

.share-buttons button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 36px;
  cursor: pointer;
}

.copy-success {
  color: black;
  font-size: large;
  margin-top: 10px;
}
.strikethrough {
  text-decoration: line-through;
  margin-right: 10px;
  color: grey;
}
