/* Container for the autocomplete component */
.autocomplete-container {
    margin: 20px;
}

/* Style for the input field */
.autocomplete-input {
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    height: 40px !important;
}

/* Style for the loading spinner */
.autocomplete-loading-spinner {
    color: #3f51b5; /* Material-UI primary color */
}

/* Style for the options list */
.autocomplete-options-list {
    max-height: 200px;
    overflow-y: auto;
}

/* Style for individual option items */
.autocomplete-option-item {
    padding: 10px;
    cursor: pointer;
}

.autocomplete-option-item:hover {
    background-color: #f0f0f0;
}

/* Style for the selected option */
.autocomplete-option-selected {
    background-color: #e0e0e0;
}

/* Additional styles for the input adornment */
.autocomplete-input-adornment {
    display: flex;
    align-items: center;
}

/* Hide the dropdown (popup) indicator */
.autocomplete-popup-indicator {
    display: none !important;
}

.autocomplete-end-adornment {
    display: none !important;
}
