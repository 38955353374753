.jguide{
    margin-top: 10%;
    margin-left: 2%;
}
.jguide {
    text-align: justify;
    padding: 20px; /* Adds padding around the content */
    line-height: 1.6; /* Adjusts the line height for better readability */
  }

  .jguide img{
    padding: 30px;
  }
  
  .jguide h2 {
    text-align: center; /* Centers the heading */
    margin-bottom: 20px; /* Adds space below the heading */
  }
  
  .jguide b {
    text-align: left; /* Keeps bold text aligned to the left */
  }
  
  .jguide p {
    margin-bottom: 15px; /* Adds space between paragraphs */
  }
  
  .jguide ul, .jguide li {
    text-align: left; /* Keeps list items aligned to the left */
    margin-left: 20px; /* Adds margin to indent the list */
  }

  @media only screen and (max-width: 600px) {
    .jguide {
        margin-top: 30%;
      padding: 10px; /* Reduce padding for smaller screens */
      font-size: 14px; /* Decrease font size for better readability */
    }
  
    .jguide h2 {
      font-size: 18px; /* Adjust heading size for mobile */
      margin-bottom: 15px;
    }
  
    .jguide ul, .jguide li {
      margin-left: 10px; /* Reduce margin for list items */
    }
  
    .jguide b {
      font-size: 16px; /* Adjust the size of bold text */
    }
  }