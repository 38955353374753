.shippingContainer {
  width: 100vw;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.shippingBox {
  background-color: #f4f4f4;
  width: 32vw; /* You can increase this if needed */
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 20px;
}

.shippingHeading {
  text-align: center;
  color: rgba(0, 0, 0, 0.664);
  font: 400 1.3vmax "Roboto";
  padding: 1.3vmax;
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
  width: 90%;
  margin: auto;
}

.shippingForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2vmax;
  justify-content: space-evenly;
  color: black;
  transition: all 0.5s;
  padding-left: 2vmax; /* Adjusted padding */
  width: 90%; /* Ensure form takes up most of the container width */
}

.shippingForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}

.shippingForm > div > input,
.shippingForm > div > select {
  flex: 1; /* Make input fields flexible to fill available space */
  padding: 1vmax; /* Adjust padding to fit larger width */
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  font: 300 0.9vmax "Robot";
  outline: none;
  margin-right: 1vmax; /* Add some spacing between fields */
}

.shippingForm > div > svg {
  position: relative;
  transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

.shippingBtn {
  border: none;
  background-color: #000000;
  color: white;
  font: 300 1vmax "Roboto";
  width: 100%;
  padding: 1vmax;
  cursor: pointer;
  transition: all 0.5s;
  outline: none;
  margin: 2vmax;
}

.shippingBtn:hover {
  background-color: #524f40;
}
.shippingForm > div > input,
.shippingForm > div > select {
  padding-left: 2vw; /* Add padding to the left for mobile */
  padding-right: 5vw; /* Add some padding to the right */
}

@media screen and (max-width: 600px) {
  .shippingBox {
    width: 100vw;
    height: 95vh;
    overflow: scroll;
  }

  .shippingHeading {
    font: 400 6vw "Roboto";
    padding: 5vw;
  }

  .shippingForm {
    padding: 11vw;
    width: 80%; /* Ensure the form takes the full width of the container */
  }

  .shippingForm > div > input,
  .shippingForm > div > select {
    width: 100%; /* Make input fields take the full width of the container */
    padding: 3vw 5vw; /* Adjust padding to fit the screen */
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.267);
    font: 300 1vmax "Roboto"; /* Ensure font size is appropriate */
  }

  .shippingForm > div > input,
.shippingForm > div > select {
  padding-left: 10vw; /* Add padding to the left for mobile */
  padding-right: 5vw; /* Add some padding to the right */
}


  .shippingForm > div > svg {
    font-size: 6vw;
    transform: translateX(3vw);
  }

  .shippingBtn {
    font: 300 4vw "Roboto";
    padding: 4vw;
  }
}
