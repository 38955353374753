.productListContainer {
  width: 100%;
  box-sizing: border-box;
  background-color: #f0f5f3;
  border-left: 1px solid rgba(0, 0, 0, 0.158);
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 1rem;
  margin-top: 100px;
  box-shadow: 0px 0px 15px rgba(0, 100, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
}

#productListHeading {
  font: 400 2rem "Roboto";
  padding: 1vmax;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.637);
  transition: all 0.5s;
  margin-bottom: 20px;
  text-align: center;
  border: 2px solid rgba(149, 156, 149, 0.931) ;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2),
  -5px -5px 15px rgba(255, 255, 255, 0.2);
  
  
}

.productListTable {
  background-color: #d6d6d6;
  border: none !important;
  font-family: 'Roboto', sans-serif;
  color: #356859;
}

.productListTable div {
  font: 300 1vmax "Roboto";
  color: rgba(0, 0, 0, 0.678);
  border: none !important;
}

.productListTable a,
.productListTable button {
  color: rgba(0, 0, 0, 0.527);
  transition: all 0.5s;
}





.MuiDataGrid-columnHeader div {
  color: #010101;
  background: #9B9A94;
}

@media screen and (max-width: 600px) {
  .productListTable div {
    font: 300 4vw "Roboto";
  }
}

.productListTable .MuiDataGrid-cell {
  border-color: #a5d6a7; /* Lighter green borders between cells */
}