.myOrdersPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background: #f5f5f7;
  border: none;
   
}


#myOrdersHeading {
  font-size: 2rem;
  color: #050202;
  margin-top: 124px;
  width: 100%;
  
  font-weight: 600;
  text-align: center;
  justify-content: center;
  padding: 1rem 2rem;
  background-color: rgb(252, 5, 5);
  backdrop-filter: blur(15px);
  border: 2px solid rgba(149, 156, 149, 0.931) ;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2),
              -5px -5px 15px rgba(255, 255, 255, 0.2);
  transform: translateZ(30px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

#myOrdersHeading:hover {
  transform: translateZ(60px);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.2),
              -10px -10px 30px rgba(255, 255, 255, 0.2);
}

.myOrdersTable  {
  font-family: 'Istok Web', sans-serif;
  background-color: #d6d6d6; /* White background for the table */
  border-radius: 10px;
  margin-top: 1rem;
  width: 95%;
  border: none;
}


.greenColor {
  color: #388e3c; /* Darker green color for delivered status */
}

.redColor {
  color: #d32f2f; /* Red color for statuses like processing, shipped, etc. */
}

.myOrdersTable .MuiDataGrid-footerContainer {
  background-color: #d6d6d6;
}

.myOrdersTable .MuiDataGrid-row:hover {
  background-color: #d6d6d6; /* Slightly darker green for hover effect */
}

.MuiDataGrid-columnHeaderTitleContainer {
  font-weight: bold;
  background-color: #d6d6d6;
}

.MuiSvgIcon-root {
  fill: #3a3a3a; 
}

.MuiSvgIcon-root:hover {
  fill: #388e3c; 
}

@media (max-width: 600px) {
  .myOrdersTable {
    width: 100%;
  }

  #myOrdersHeading {
    font-size: 1.5rem;
  }
}
.MuiDataGrid-columnHeader--sortable {
  background-color: #9B9A94;
}
