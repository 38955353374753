/* ProductCard.css */

.productCard {
  position: relative;
  display:flex;
  justify-content: center;
  flex-direction:column;
  text-decoration: none;
  color: #1e1e1e;
  margin: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
  /* flex: 1 1 calc(25% - 40px); */
  box-sizing: border-box;
  width: 250px;
  height: 400px;
}

.productCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.imgBox {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  height: 150px;
  width: 100%;
  transition: transform 0.3s;
}

.productCard:hover .imgBox {
  transform: scale(1.1);
}

.productImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  z-index: 2;
}

.contentBox {
  position: relative;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  transition: background 0.3s;
  width: 100%;
}

.productCard:hover .contentBox {
  background: rgba(255, 255, 255, 0.95);
}

.contentBox h4 {
  font-size: 1rem;
  color: #1e1e1e;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  transition: color 0.3s;
  white-space: nowrap;   /* Prevents wrapping of the text */
  overflow: hidden;      /* Ensures content beyond the box is hidden */
  text-overflow: ellipsis; /* Adds the ellipsis (...) for long text */
  max-width: 100%; /* Ensure the element doesn't stretch beyond container */
}

.productCard:hover .contentBox h3 {
  color: #EBE4DE;
}

.productCardSpan {
  margin: 5px 0;
  font: 300 0.7rem "Roboto";
}
.buynow{
  display: flex;
}
.price {
  font-size: 1.2rem;
  color: #1e1e1e;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: 10px;
}

.buy {
  margin-left: 3px;
  margin-top: 15px;
  font-weight: 700;
  padding: 10px 20px;
  color:  #EBE4DE;
  text-decoration: none;
  background:#0a1e01;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  border: #EBE4DE solid 1px;
  box-shadow: 0 5px 15px rgba(81, 82, 81, 0.4);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.buy::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  transition: all 0.3s ease;
  z-index: -1;
  transform: scale(0.9);
  opacity: 0;
}

.buy:hover {
  color:#ffffff;
  background: #524f40;
  box-shadow: 0 8px 20px rgba(52, 54, 52, 0.5);
}

.buy:hover::before {
  opacity: 1;
  transform: scale(1.1);
}

/* Media Queries for Responsiveness */

@media (max-width: 1200px) {
  .productCard {
    flex: 1 1 calc(33.33% - 40px);
  }

  .imgBox {
    height: 200px;
  }
}

@media (max-width: 992px) {
  .productCard {
    flex: 1 1 calc(50% - 40px);
  }

  .imgBox {
    height: 180px;
  }
}

@media (max-width: 768px) {
  .productCard {
    flex: 1 1 calc(100% - 40px);
  }

  .imgBox {
    height: 160px;
  }

  .contentBox h3 {
    font-size: 1rem;
  }

  .price {
    font-size: 1rem;
  }

  .buy {
    font-size: 0.9rem;
    padding: 8px 15px;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .imgBox {
    height: 140px;
  }

  .contentBox h3 {
    font-size: 0.9rem;
  }

  .price {
    font-size: 0.9rem;
  }

  .buy {
    font-size: 0.8rem;
    padding: 6px 12px;
    align-items: center;
  }
}
.strikethrough {
  text-decoration: line-through;
  margin-right: 10px;
  color: grey;
}