* {
  margin: 0;
  scroll-behavior: smooth;
}

body {
  /* cursor: url("./images/cursor1.png"),auto; */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.greenColor {
  color: green !important;
}
.redColor {
  color: red !important;
}


