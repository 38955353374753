.refund{
    margin-left: 5%;
    margin-top: 10%;
    
}


@media screen and (max-width: 600px) {
    .refund { 
      margin-top: 40%; /* Adjust this value to control how far down the heading should appear */
    }
    
  }
  