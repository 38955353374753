/* Default styles for larger screens */
.artcile {
    margin-left: 3%;
    margin-top: 13%;
  }
  
  .article-content h3 {
    text-align: center;
  }
  
  .article-text {
    margin-top: 20px;
  }
  
  
  .image-container {
    display: flex;
    justify-content: space-between; /* Adjust as needed */
  }
  .image{
    transition: 1s ease;
  }
  .image:hover{
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    transition: 1s ease;
    }

   
  .bangle-container {
    display: flex;
    justify-content: space-between; /* Adjust as needed */
  }
  .bangle{
    transition: 1s ease;
  }
  .bangle:hover{
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: 1s ease;
    }
  .chain{
    transition: 1s ease;
  }
  .chain:hover{
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    transition: 1s ease;
  }
  .earing{
    transition: 1s ease;
  }
  .earing:hover{
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: 1s ease;
  }


  /* Styles for mobile view */
  @media only screen and (max-width: 768px) {
    /* Adjust padding for mobile */
    .artcile{
      margin-top: 35%;
    }
    .article {
      padding: 5px;
  
    }
  
    .article-content {
      padding: 0 5px;
    }
  
    /* Adjust headings for mobile view */
    h3 {
      font-size: 1.1em; /* Make main heading smaller */
    }
  
    h4 {
      font-size: 1.1em; /* Make subheading smaller */
    }
  
    /* Ensure images don't overflow */
    img {
      width: 100%; /* Make images responsive */
      max-width: 100%;
      height: auto;
      display: block; /* Ensures the images don't overflow horizontally */
      margin: 0 auto;
    }
  
    /* Adjust font size and line height for mobile readability */
    .article-text {
      font-size: 0.85em; /* Slightly smaller font for mobile */
      line-height: 1.4; /* Adjust line height for better spacing */
    }
  
    /* Reduce gaps between elements */
    .image-container, .bangle-container, .chain-container, .earing-container {
      margin: 10px 0; /* Reduce the space between images and text */
    }
  }
  