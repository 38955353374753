.dashboard {
  margin-top: 10%;
  width: 100vw;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 5fr;
  position: relative;
}

.dashboardContainer {
  border-left: 1px solid rgba(0, 0, 0, 0.13);
  background-color: rgb(255, 255, 255);
  padding: 3rem 0;
}

.dashboardContainer > h1 {
  color: rgba(0, 0, 0, 0.733);
  font: 300 2rem "Roboto";
  text-align: center;
  width: 50%;
  padding: 1.5rem;
  margin: auto;
}

.dashboardSummary {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.dashboardSummary > div {
  display: flex;
  background-color: white;
  justify-content: center;
  padding: 1rem;
}

.dashboardSummary > div > p {
  background-color: rgba(70, 218, 188, 0.932);
  color: white;
  font: 300 1.3rem "Roboto";
  text-align: center;
  border-radius: 10px;
  padding: 1.5rem;
  width: 100%;
  margin: 2rem 2rem;
}

.totalAmountValue {
  font-size: 40px;
  font-weight: bold;
  font-style: italic;
}

.dashboardSummaryBox2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.dashboardSummaryBox2 > a {
  color: rgb(0, 0, 0);
  font: 300 1.5rem "Roboto";
  text-align: center;
  background-color: rgb(255, 233, 174);
  text-decoration: none;
  padding: 1.5rem;
  width: 10vmax;
  height: 10vmax;
  margin: 2rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dashboardSummaryBox2 > a:first-child {
  background-color: rgb(255, 110, 110);
  color: rgb(15, 10, 10);
}

.dashboardSummaryBox2 > a:nth-child(2) {
  background-color: rgb(255, 233, 174);
  color: rgb(15, 10, 10);
}

.dashboardSummaryBox2 > a:last-child {
  background-color: rgb(117, 162, 66);
  color: rgb(15, 10, 10);
}

.charts1 {
  display: flex;
  /* flex-direction: row; */
  /* flex-wrap: wrap;
  justify-content: center; */
}

.lineChart,
.doughnutChart,
.pieChart,
.barChart {
  width: 80%;
  margin: 1.5rem auto;
}

.doughnutChart {
  width:10vmax;
 
}

.pieChart {
  width: 10vmax;
}

/* Mobile Devices (max-width: 600px) */
@media screen and (max-width: 600px) {
  .dashboard {
    padding-top: 5%;
    grid-template-columns: 1fr;
  }

  .dashboardContainer {
    border-left: none;
  }

  .dashboardSummary > div > p {
    margin: 0;
    padding: 0.5rem;
    font: 300 0.5rem "Roboto";
    width: 60%;
  }
  .dashboardSummary{
    display: flex;
    flex-direction: column;
  }


  .dashboardSummaryBox2 > a {
    padding: 0.5rem;
    margin: 0.5rem;
    font: 300 0.5rem "Roboto";
    width: 6vmax;
    height: 6vmax;
  }
  .charts1 {
    flex-direction: column; /* Ensure charts are stacked vertically */
    align-items: center;
  }

  .lineChart,
  .doughnutChart,
  .pieChart,
  .barChart {
    width: 80%;
  }
}

/* Tablets (max-width: 768px) */
@media screen and (max-width: 768px) {
  .dashboard {
    grid-template-columns: 1fr;
  }

  .dashboardContainer {
    padding: 2rem 0;
  }

  .dashboardSummaryBox2 > a {
    padding: 1.2rem;
    margin: 1.5rem;
    font: 300 1.2rem "Roboto";
    width: 9vmax;
    height: 9vmax;
  }
  .charts1 {
    flex-direction: column;
    align-items: center;
  }

  .lineChart,
  .doughnutChart,
  .pieChart,
  .barChart {
    width: 50%;
  }
}

/* Small Laptops (max-width: 1024px) */
@media screen and (max-width: 1024px) {
  .dashboard {
    grid-template-columns: 1fr 4fr;
  }

  .dashboardSummaryBox2 > a {
    padding: 1.3rem;
    margin: 1.5rem;
    font: 300 1.3rem "Roboto";
    width: 9.5vmax;
    height: 9.5vmax;
  }

  .lineChart,
  .doughnutChart,
  .pieChart,
  .barChart {
    width: 80%;
  }
}

/* Large Desktops (min-width: 1280px) */
@media screen and (min-width: 1280px) {
  .dashboard {
    grid-template-columns: 1fr 5fr;
  }

  .dashboardSummaryBox2 > a {
    padding: 1.5rem;
    margin: 2rem;
    font: 300 1.5rem "Roboto";
    width: 10vmax;
    height: 10vmax;
  }

  .lineChart,
  .doughnutChart,
  .pieChart,
  .barChart {
    width: 75%;
  }
}
