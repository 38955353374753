.AddCouponForm {
 margin-top: 0%;
  margin-left: 40%;
  width: 500px;
  height: 710px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  max-width: 500px;
  /*margin: 0 auto;*/
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: burlywood;
}

/* Style for form groups */
.form-group {
  margin-bottom: 1rem;
  width: 90%;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.input-field[type="text"] {
  margin:auto;
  width:90%;
  cursor: pointer;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.input-field {
  width: 90%;
  cursor: pointer;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.input-field[type="checkbox"] {
  width: auto;
  margin-left: 0.5rem;
}

.submit-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  margin-top: 1rem;
}
.couponsTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.couponsTable th,
.couponsTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.couponsTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f2f2f2;
  color: black;
}


/* Media query for smaller screens */
@media (max-width: 600px) {
  .couponForm {
    margin-top: 30%;
    margin-left: 30%;
    padding: 0.5rem;
  }

  .input-field {
    font-size: 0.875rem;
    padding: 0.25rem;
  }

  .submit-button {
    font-size: 0.875rem;
    padding: 0.5rem;
  }
}

/* Media query for larger screens */
@media (min-width: 601px) {
  .couponForm {
    padding: 2rem;
  }

  .input-field {
    font-size: 1.25rem;
    padding: 0.75rem;
  }

  .submit-button {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
  }
}

