/* Resetting base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Istok Web", sans-serif;
  transition: all 0.3s ease;
  text-decoration: none;
  
}

/* Products Heading */
.productsHeading {
  margin: 2rem auto; /* Adjust margin to control spacing */
  width: calc(100% - 2rem); /* Responsive width */
  max-width: 1200px; /* Max width for larger screens */
  text-align: center;
  font-size: 2rem;
  color: #333;
  font-weight: 700;
  padding: 1rem;
  background: #E0CBC4;
  border-radius: 10px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  z-index: 2;
  letter-spacing: 5px;
  text-transform: uppercase;
  backdrop-filter: blur(15px);
  border: 1px solid rgba(130, 127, 127, 0.214);
}

/* Products Container */
.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 1rem;
  background: #f8f8f8;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  margin-top: 1rem; /* Adjust top margin */
}

/* Additional container styles if needed */
.container {
  display: flex;
  margin: 2rem auto;
  width: 90%;
  max-width: 1200px; /* Max width for larger screens */
  flex-wrap: wrap;
  justify-content: center;
}

.container1 {
  display: flex;
  justify-content: center;

}




/* Filter Box */
.filterBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  padding: 0.5rem;
  background: #E0CBC4;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  margin: 1rem auto; /* Center and add margin */
  width: calc(100% - 2rem); /* Responsive width */
  max-width: 1000px; /* Max width for larger screens */
  z-index: 10;
  margin-top: 10rem;
}

.filterBox > * {
  flex: 1;
  min-width: 120px; /* Adjust minimum width for responsiveness */
  margin: 5px;
}

/* Category Links */
.category-link {
  color: #555;
  margin: 1rem;
  padding: 1rem;
  border-radius: 5px;
  background: #c3f8ae;
  text-align: center;
  display: block;
}

.category-link:hover, .category-link.active {
  color: white;
  background: #4caf50;
}

/* Subcategories styling */
.subcategoryBox {
  list-style-type: none;
  padding: 20px;
  margin-top: 1rem;
}

.subcategory-link {
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  background: #e9ecef;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: block;
  color: #333;
  text-align: center;
}

.subcategory-link:hover, .subcategory-link.active {
  background: #4caf50;
  color: #fff;
}

/* Reset Filters Button */
.resetFilterBtn {
  padding: 0.5rem 1rem;
  background-color: #dc3545; /* A color that stands out */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-weight: bold; /* Make the text bold */
}

.resetFilterBtn:hover {
  background-color: #c82333;
}

/* Dropdown Select Styling */
.categorySelect, .subcategorySelect {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: white;
  cursor: pointer;

}
.priceSelect{
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: white;
  cursor: pointer;
}

.option{
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: white;
  cursor: pointer;
}

.ratingsSelect{
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: white;
  cursor: pointer;
}





.filterBox input[type="range"] {
  width: 100%; /* Full width to match other elements */
}

.categorySelect:focus, .subcategorySelect:focus {
  outline: none;
  border-color: #4caf50;
}

/* Responsive Adjustments */
@media screen and (max-width: 1024px) {
  .productsHeading {
    font-size: 1.8rem;
    margin: 2rem auto;
    padding: 0.5rem;
  }

  .filterBox {
    width: calc(100% - 2rem);
    margin-top: 40%; /* Adjust width on medium screens */
  }
}

@media screen and (max-width: 768px) {
  .filterBox {
    width: calc(100% - 2rem); /* Adjust width for small screens */
    padding: 0.5rem;
    flex-direction: column; /* Stack elements vertically */
  }

  .filterBox > * {
    width: 100%; /* Full width for smaller elements */
    margin-bottom: 0.5rem;
  }

  .category-link, .subcategory-link {
    font-size: 0.9rem; /* Adjust font size */
  }
}

@media screen and (max-width: 600px) {
  .productsHeading {
    font-size: 1.5rem;
    margin: 1.5rem auto; /* Adjust margin */
  }

  .filterBox {
    width: 100%;
    padding: 0.5rem;
  }

  .filterBox > * {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .container {
    width: 100%;
  }
}
/* Pagination Box */
.paginationBox {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  background: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  margin: 2rem auto; /* Center the pagination box */
  flex-wrap: wrap; /* Allows pagination items to wrap on smaller screens */
  width: 100%;
}

.paginationBox ul {
  display: flex;
  gap: 0.5rem; /* Adjust spacing between items */
  flex-wrap: wrap; /* Allows pagination items to wrap on smaller screens */
  justify-content: center;
  padding: 0;
  margin: 0;
}

.page-item {
  background-color: #e0e0e0;
  list-style: none;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  border-radius: 5px;
  color: #333333;
  text-align: center;
}

.page-item:hover {
  background-color: #cacaca;
}

.page-item.active {
  background-color: #4caf50;
  color: white;
}

/* Responsive Pagination Adjustments */
@media screen and (max-width: 768px) {
  .paginationBox ul {
    gap: 0.25rem; /* Reduce the gap on smaller screens */
  }

  .page-item {
    padding: 0.4rem 0.8rem; /* Reduce padding for smaller screens */
    margin: 0.2rem;
  }
}

@media screen and (max-width: 600px) {
  .paginationBox ul {
    gap: 0.2rem; /* Further reduce gap */
  }

  .page-item {
    padding: 0.3rem 0.6rem; /* Further reduce padding */
    font-size: 0.9rem; /* Reduce font size */
  }
}
