/* Base styles */
.container {
  max-width: 50%;
  margin: 50px auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

form {
  display: grid;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 1rem;
  background-color: #f4f4f4;
  border: 1px solid rgba(231, 92, 92, 0.1);
  gap: 3px; /* Add gap between fields */
  margin-top: 10rem;
}

label {
  font-weight: bold;
}

/* Equal size for input, select, and button */
input[type="text"],
select,
button[type="submit"] {
  font-family: "Robot";
  width: 100%; /* Ensure all elements are full width */
  margin-bottom: -250%;
  padding: 12px 20px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Make sure padding and borders are included in the total width */
}

button[type="submit"] {
  background-color: rgb(0, 0, 0);
  color: white;
  margin: 10px 0;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #524f40;
}

.saved-addresses-container {
  width: 100%;
  max-width: 800px;
  margin: 3rem auto;
  border: 1px solid rgba(231, 92, 92, 0.1);
}

.saved-addresses-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.saved-addresses-container ul {
  list-style: none;
  padding: 0;
}

.saved-addresses-container li {
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.saved-addresses-container li:hover {
  transform: translateY(-2px);
  transition: transform 0.2s ease;
}

.saved-addresses-container li p {
  margin: 8px 0;
}

.saved-addresses-container li button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: rgb(0, 0, 0);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 60%;
}

.saved-addresses-container li button:hover {
  background-color: #524f40;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .container {
    margin: 30px auto;
  }

  form {
    width: 90%;
  }

  .saved-addresses-container {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 15px;
    margin: 20px auto;
  }

  form {
    width: 100%;
    margin: 0;
    padding: 10px;
    margin-top: 70px;
  }

  input[type="text"],
  select,
  button[type="submit"] {
    width: 100%;
    margin: 8px 0;
  }

  button[type="submit"] {
    width: 100%;
  }

  .saved-addresses-container {
    width: 100%;
  }

  .saved-addresses-container li {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 10px;
  }

  form {
    padding: 8px;
    margin-top: 8rem;
  }

  input[type="text"],
  select,
  button[type="submit"] {
    padding: 10px;
  }

  button[type="submit"] {
    padding: 12px;
  }

  .saved-addresses-container {
    padding: 0;
  }
}
