.shipolicy{
    margin-left: 2%;
    margin-top: 12%;
    
}


@media screen and (max-width: 600px) {
    .shipolicy { 
      margin-top: 40%; /* Adjust this value to control how far down the heading should appear */
    }
    
  }
  