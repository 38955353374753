.paragraph-page {
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.paragraph-page h2 {
  color: #333;
  margin-left: -80%;
  margin-top: 0; /* Reset any top margin */
  padding-top: 20px; /* Add some padding to the top for spacing */
}

.paragraph-page p {
  color: #666;
}

@media screen and (max-width: 600px) {
  .refund { 
    margin-top: 20%; /* Adjust this value to control how far down the heading should appear */
  }
  
  .paragraph-page h2 {
    margin-left: 0; /* Reset the left margin on smaller screens */
    margin-top: 35%; /* Ensure the top margin doesn't push it too far down */
    padding-top: 10px; /* Adjust padding as needed to ensure visibility */
    font-size: 1.5rem; /* Adjust font size to fit within the screen */
  }
}
