.profileContainer {
  display: flex;
  height: auto; /* Use auto to avoid overflow issues */
  width: 100%;
  max-width: 100%;
  background-color: #F0EDE6;
  margin: 20px auto; /* Center the container and add margin */
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.profileContainer{
  margin-top: 8%;
}

.profileContainer > div {
  display: flex;
  flex: 1; /* Allow the div to grow */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.profileContainer > div:first-child > h1 {
  color: rgba(0, 0, 0, 0.555);
  font: 500 2.2vmax "Roboto";
  transform: translateX(-10vmax) translateY(-2vmax);
}

.profileContainer > div:first-child > img {
  width: 15vmax;
  border-radius: 60%;
  height: auto; /* Maintain aspect ratio */
  transition: transform 0.5s;
  border: 1px solid black;
}

.profileContainer > div:first-child > img:hover {
  transform: scale(1.05);
}

.profileContainer > div:first-child > a {
  border: none;
  background-color: #000000;
  color: white;
  text-decoration: none;
  padding: 0.5vmax;
  width: 30%;
  margin: 4vmax;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(49, 22, 1, 0.4);
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
}

.profileContainer > div:first-child > a:hover {
  background-color: #524f40;
  transform: translateY(-3px);
}

.profileContainer > div:last-child {
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 5vmax;
  box-sizing: border-box;
}

.profileContainer > div:last-child > div > h4 {
  background-color: #c3a3a3;
  border-radius: 10px;
  border: 1px solid rgba(234, 234, 234, 0.777);
  color: black;
  font: 400 1.2vmax "Roboto";
  padding: 10px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  margin-top: 10px;
  overflow: hidden;
}

.profileContainer > div:last-child > div > p {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  color: rgb(0, 0, 0);
  font: 400 16px "Robot";
  padding: 10px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profileContainer > div:last-child > div:last-child {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap */
  width: 100%;
  margin-left: -1rem;
  margin-top: 2rem;
}

.profileContainer > div:last-child > div:last-child > a {
  border: none;
  border-radius: 2px;
  width: 30%;
  background-color: rgb(0, 0, 0);
  font: 400 1vmax "Roboto";
  color: white;
  text-decoration: none;
  padding: 0.5vmax;
  text-align: center;
  transition: background-color 0.5s;
  margin: 0.5vmax 1vmax;
}

.profileContainer > div:last-child > div:last-child > a:hover {
  background-color: #524f40;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  .profileContainer {
    flex-direction: column; /* Stack children vertically */
  }
}

@media screen and (max-width: 768px) {
  .profileContainer > div:first-child > h1 {
    font: 500 3vmax "Roboto";
    transform: translateY(-1.5vmax);
  }

  .profileContainer > div:first-child > a {
    font: 400 1.5vmax "Roboto";
    padding: 1vmax;
    width: 50%; /* Adjust width for smaller screens */
  }

  .profileContainer > div:last-child {
    padding: 2vmax;
    align-items: center;
  }

  .profileContainer > div:last-child > div > h4 {
    font: 400 2vmax "Roboto";
  }

  .profileContainer > div:last-child > div > p {
    font: 400 1.5vmax "Robot";
  }

  .profileContainer > div:last-child > div:last-child > a {
    font: 400 1.5vmax "Roboto";
    width: 45%;
  }
}

@media screen and (max-width: 600px){
  .profileContainer{
    margin-top: 50%;

  }

  .profileContainer > div:first-child > h1 {
    font: 500 3.2vmax "Roboto";
    transform: translateY(-1vmax);
  }

  .profileContainer > div:first-child > a {
    font: 400 1.7vmax "Roboto";
    padding: 1vmax;
  }

  .profileContainer > div:last-child {
    text-align: center;
    align-items: center;
  }

  .profileContainer > div:last-child > div > h4 {
    font: 400 2.5vmax "Roboto";
  }

  .profileContainer > div:last-child > div > p {
    font: 400 2vmax "Robot";
    margin: 0.5vmax 0;
  }

  .profileContainer > div:last-child > div:last-child > a {
    font: 400 1.8vmax "Roboto";
    width: 55%;
    margin: 2vmax 10vmax;
  }
}
