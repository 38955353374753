.emptyCart {
  margin: auto;
  text-align: center;
  padding: 10vmax;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.emptyCart > svg {
  font-size: 5vmax;
  color: rgb(95, 255, 71);
}
.emptyCart > p {
  font-size: 2vmax;
}
.emptyCart > a {
  background-color: rgb(221, 216, 216);
  color: white;
  border: none;
  padding: 1vmax 3vmax;
  cursor: pointer;
  font: 400 1vmax "Roboto";
  text-decoration: none;
}

.cartPage {
  padding: 5vmax;
  margin-top: 100px;
  margin-bottom: 200px;
}

.cartHeader {
  background-color: rgb(163, 255, 71);
  width: 90%;
  box-sizing: border-box;
  margin: auto;
  color: black;
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  font: 300 0.7vmax "Roboto";
}
.cartHeader > p {
  margin: 10px;
}
.cartHeader > p:last-child {
  text-align: end;
}

.cartContainer {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
}

.cartInput {
  display: flex;
  align-items: center;
  height: 8vmax;
}

.cartInput > button {
  border: none;
  background-color: rgba(0, 0, 0, 0.616);
  padding: 0.5vmax;
  cursor: pointer;
  color: white;
  transition: all 0.5s;
}
.cartInput > button:hover {
  background-color: rgba(0, 0, 0, 0.767);
}

.cartInput > input {
  border: none;
  padding: 0.5vmax;
  width: 2vmax;
  text-align: center;
  outline: none;
  font: 400 0.8vmax "Roboto";
  color: rgba(0, 0, 0, 0.74);
}

.cartSubtotal {
  display: flex;
  padding: 0.5vmax;
  height: 8vmax;
  align-items: center;
  box-sizing: border-box;
  font: 300 1vmax "Robot";
  justify-content: flex-end;
  color: rgba(0, 0, 0, 0.753);
}

.cartGrossProfit {
  display: grid;
  grid-template-columns: 2fr 1.2fr;
}

.cartGrossProfitBox {
  border-top: 3px solid #9B9A94;
  margin: 1vmax 4vmax;
  box-sizing: border-box;
  padding: 2vmax 0;
  font: 300 1vmax "Roboto";
  display: flex;
  justify-content: space-between;
}

/*.checkOutBtn{
  margin:top -20px;
  margin-right: 10%;
  
}*/
/*.checkOutBtn > button {
  background-color: #000000;
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  width: 120px;
  margin-left: 80%;
  margin-top: -250%; 
  font: 14px "Roboto";
  cursor: pointer;
  
}*/

/*.checkOutBtn > button:hover{
  background-color: #524f40;
}*/

.couponError{
  font-size: 16px;
  color: #000000;
  
}

.apply{
  margin-left: 70%;
  margin-bottom: -250%;
  padding: 5px;
  background-color: #000000;
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  width: 120px;
  cursor: pointer;
  
}

.apply:hover{
  background-color: #524f40;
}
.checkOutBtn{
  padding: 10px 20px;
  margin-bottom: -250%;
  background-color: #000000;
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  width: 120px;
  cursor: pointer;
}

.checkOutBtn:hover{
  background-color: #524f40;
}

.couponContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Full width of parent */
  margin: auto;
  margin-top: 2rem;
}
.couponSection{
  margin-left: 5rem;
  width: 90%;
}
.couponField {
  flex: 1; /* Take remaining space for the input */
  margin-right: 1rem;
}

.apply, .checkOutBtn {
  background-color: #000000;
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  width: auto; /* Let the buttons adjust based on content */
  cursor: pointer;
  font: 14px "Roboto";
  text-align: center;
}

.apply:hover, .checkOutBtn:hover {
  background-color: #524f40;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 9%; /* Adjust width as needed */
  margin: auto;
  margin-top: 50%;
}

.apply {
  background-color: #000000;
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  width: 9%; /* Adjust button width to fit side by side */
  cursor: pointer;
  font: 14px "Roboto";
  text-align: center;
}
.checkOutBtn {
  background-color: #000000;
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  width: 9%; /* Adjust button width to fit side by side */
  cursor: pointer;
  font: 14px "Roboto";
  text-align: center;
}

.apply:hover, .checkOutBtn:hover {
  background-color: #524f40;
}

@media screen and (max-width: 600px) {
  .cartPage {
    padding: 0;
    min-height: 60vh;
    margin-top: 160px; /* Adjusted for smaller screens */
  }

  .cartHeader {
    width: 100%;
    font: 300 1.2rem "Roboto"; /* Use rem for more control */
    grid-template-columns: 2fr 1fr 1fr; /* Reduced to fit better */
  }

  .cartContainer {
    width: 100%;
    grid-template-columns: 1fr; /* Stack items on mobile for better fit */
  }

  .cartInput {
    height: auto; /* Let it adjust based on content */
    margin-bottom: 1rem;
    margin-left: 12rem; /* Add some space between items */
  }

  .cartInput > button {
    padding: 1rem; /* Adjust button size */
  }

  .cartInput > input {
    width: 3rem;
    padding: 0.8rem;
    font: 400 1.2rem "Roboto"; /* Reduced font size */
  }

  .cartSubtotal {
    padding: 1rem;
    font: 300 1.4rem "Roboto";
  }

  .cartGrossProfit {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Adjust grid for better spacing */
  }

  .cartGrossProfitBox {
    padding: 3rem;
    font: 300 1.4rem "Roboto";
    margin-left: -40%;
  }

  .checkOutBtn > button {
    padding: 1.2rem 2rem;
    width: 100%;
    font: 300 1.5rem "Roboto";
  }

  .buttonContainer {
    flex-direction: row;
    justify-content: space-between;
  }

  .apply, .checkOutBtn {
    width: 30%;
    margin-left: 35%;
    margin-top: 1rem; /* Ensure the buttons are side by side in mobile view */
  }
  .checkOutBtn {
    width: 30%;
    margin-left: 35%;
    margin-top: 1rem; /* Ensure the buttons are side by side in mobile view */
  }
  .couponSection{
    margin-left: 1rem;
    margin-right: 2rem;
  }
 

}
