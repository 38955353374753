.about-jewellery{
  margin-top: 10%;
  margin-left: 3%;
}
.about-jewellery {
  padding: 2vmax;
  font-family: 'Arial', sans-serif;
}

.heading-part h3 {
  font-size: 2rem;
  text-align: center;
}

.para1 {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-top: 1.5vmax;
}

.image-gap {
  margin-right: 8px; /* Adjust the value as needed */
}

.image-gap:hover{
  height: 50px;
  width: 50px;
}

.image-gap {
  margin-right: 10px;
  vertical-align: middle;
}
/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .about-jewellery {
    margin-top: 35%;
    padding: 1vmax; /* Reduce padding for mobile */
  }

  .heading-part h3 {
    font-size: 1.5rem; /* Smaller font size for heading */
    margin-bottom: 1vmax;
  }

  .para1 {
    font-size: 1rem; /* Reduce font size for mobile */
    line-height: 1.5; /* Adjust line height */
    text-align: justify; /* Align text for readability */
  }
  .image-gap {
    width: 30px; /* Adjust size based on how small you want the images */
    height: auto; /* Ensures the aspect ratio is maintained */
  }
}
