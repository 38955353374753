.bguide{
    margin-top: 10%;
    margin-left: 1%;
}

.bangle-size-guide {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Light background for the container */
}

table {
    width: 50%; /* Adjust width of the table */
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 14px; /* Font size for the table */
    background-color: #fff; /* Light background for the table */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for table */
}

table thead th {
    background-color: powderblue; /* Light blue header background */
    padding: 10px; /* Padding for table headers */
    font-weight: bold;
    border-bottom: 2px solid #ddd;
    text-align: center; /* Center align headers */
}

table tbody td {
    padding: 10px; /* Padding for table body */
    border-bottom: 1px solid #ddd;
    text-align: center; /* Center align values in table */
}

table tr:nth-child(even) {
    background-color: #f1f8e9; /* Light green for alternating rows */
}

/* Mobile View */
@media (max-width: 600px) {
    .bguide{
        margin-top: 40%;
        margin-left: 3%;
    }
    .bangle-size-guide {
        padding: 10px;
    }

    table {
        width: 90%; /* Full width for mobile view */
        font-size: 12px; /* Slightly smaller font size */
    }

    table thead th, table tbody td {
        padding: 8px; /* Adjust padding for mobile */
        text-align: center; /* Center align values in mobile */
    }
}
