/* Base styles */
.story-head {
    padding: 20px;
    margin-top: 10%;
  }
  
  .story-head h3 {
    font-size: 2rem;
    text-align: center;
  }
  
  .craft{
    width: 60%;
    height: 400px;
  }

  .craft{
    transition: 1s ease;
  }
  .craft:hover{
    border-radius:50%;
    transition: 1s ease;
  }

  .art{
    width: 60%;
    height: 400px;
  }
  .art{
    transition: 1s ease;
  }
  .art:hover{
    border-radius:50%;
    transition: 1s ease;
  }
  .design{
    transition: 1s ease;
  }
  .design:hover{
    border-radius:50%;
    transition: 1s ease;
  }
  .story-text {
    font-size: 1.2rem;
    line-height: 1.6;
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
    text-align: justify; /* Ensures that text is aligned properly across lines */
  }
  
  .story-text p {
    margin-bottom: 20px; /* Adds spacing between paragraphs */
  }

  .craft,.art {
    width: 735px;
    height: 408px;
    object-fit: cover; /* Ensures the image fills the space and maintains its aspect ratio */
  }
  
  
  /* Responsive styles for mobile */
  @media only screen and (max-width: 768px) {

    .story-head{
        margin-top: 35%;
    }
    .story-head h3 {
      font-size: 1.5rem; /* Reduce heading size */
    }
  
    .story-text {
      font-size: 0.9rem; /* Reduce text size */
      padding: 10px; /* Reduce padding for better fit */
    }
  
    .story-text img {
      max-width: 100%; /* Make sure images fit on mobile */
    }
  }
  