/* Base Footer Styling */
footer {
  margin-top: 3vmax;
  padding: 2vmax;
  background-color:  #E0CBC4;
  color: rgb(6, 6, 6);
}

footer h1{
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1vmax;
}

.title {
  display: flex;
  justify-content: center;
  padding: 1vmax;
}

.center {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  padding: 1vmax;
  flex-wrap: wrap;
}

.col {
  border-bottom: 1rem;
}

a {
  color: black;
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  font-size: 1.15rem;
  margin-bottom: 1em;
  color: rgb(6, 6, 6);
}

.bottom {
  display: flex;
  background-color: #a69994;
  justify-content: space-between;
  padding: 1rem;
  flex-wrap: wrap;
}

.bottom a {
  color: rgb(6, 6, 6);
  text-decoration: none;
  margin: 0 0.5rem;
}

.bottom a:hover {
  text-decoration: underline;
}

.query {
  color: black;
  padding-left: 40px;
}

.refund a {
  color: black;
  text-decoration: none;
}

.org-web a {
  color: black;
  text-decoration: none;
}

.fa:hover {
  opacity: 0.9;
}

.fa-instagram, .fa-youtube, .fa-facebook, .fa-linkedin {
  background: #E0CBC4;
  color: rgb(23, 22, 22);
}

.col col-2 .refund {
  color: rgb(6, 6, 6);
  text-decoration: none;
  margin: 0 0.5rem;
}

.col col-4 .knowus {
  text-decoration: none;
  color: black;
}

.fa {
  padding: 5px;
  font-size: 1.5rem;
  text-decoration: none;
  border-radius: 50%;
  transition: opacity 0.3s;
}

/* Mobile View */
@media (max-width: 768px) {
  footer h1 {
    font-size: 1.5rem;
  }

  .title {
    font-size: 1rem;
    text-align: center;
    padding: 1vmax;
  }

  .center {
    flex-direction: column; /* Stack content vertically */
    align-items: center;
    padding: 1rem 0; /* Adjust padding for mobile */
  }

  .col-2 li, .col-3 li, .col-4 li {
    text-align: center;
    margin-bottom: 10px; /* Reduce margin between list items */
  }

  .bottom {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    text-align: center;
    padding: 1rem;
  }

  .bottom a {
    margin: 0.5rem 0; /* Adjust link margin for better spacing */
  }

  .fa {
    font-size: 1.2rem; /* Reduce icon size */
    padding: 10px;
  }
}
