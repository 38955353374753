.orderDetailsPage {
  background: #f5f5f7;
  min-height: 100vh;
  padding: 3rem 0;
}

.orderDetailsContainer {
  background: #fff;
  margin: auto;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
}

.orderDetailsContainer h1 {
  font-weight: bold;
  color: #333;
  margin-bottom: 2rem;
}

.orderDetailsContainerBox {
  background: #f8f8f8;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
}

.orderDetailsContainerBox div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
}

.orderDetailsContainerBox p {
  margin: 0;
  font-weight: 500;
  color: #333;
}

.greenColor {
  color: #4caf50;
}

.redColor {
  color: #f44336;
}

.orderDetailsCartItems {
  background: #fff;
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: auto;
}

.orderDetailsCartItemsContainer div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding: 1rem 0;
}

.orderDetailsCartItemsContainer div img {
  width: 80px;
  height: auto;
  margin-right: 1rem;
}

.orderDetailsCartItemsContainer div a {
  text-decoration: none;
  color: #333;
  flex-grow: 1;
}

.orderDetailsCartItemsContainer div span {
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .orderDetailsContainer,
  .orderDetailsCartItems {
    padding: 1rem;
  }

  .orderDetailsContainerBox div,
  .orderDetailsCartItemsContainer div {
    flex-direction: column;
    align-items: flex-start;
  }

  .orderDetailsCartItemsContainer div img {
    margin-bottom: 1rem;
  }
}
