.searchBox {
  width: 200vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(231, 231, 231);
  position: fixed;
  top: 0%;
  left: 0;
}

.searchBox > input[type="text"] {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.274);
  background-color: white;
  border: none;
  color: rgba(0, 0, 0, 0.637);
  padding: 1vmax 2vmax;
  width: 50%;
  outline: none;
  border-radius: 0%;
  font: 300 1.1vmax cursive;
  box-sizing: border-box;
  height: 8%;
}

.searchBox > input[type="submit"] {
  height: 8%;
  border-radius: 0%;
  background-color: tomato;
  border: none;
  padding: 1vmax;
  width: 10%;
  font: 300 1.1vmax "Roboto";
  cursor: pointer;
  color: white;
  transition: all 0.5s;
}

.searchBox > input[type="submit"]:hover {
  background-color: rgb(55, 97, 214);
}

@media screen and (max-width: 600px) {
  .searchBox > input[type="text"] {
    width: 100%;
    font: 300 4vw cursive;
    height: 10%;
  }

  .searchBox > input[type="submit"] {
    height: 10%;
    width: 30%;
    font: 300 4vw "Roboto";
  }
}
