.news{
    margin-top: 12%;
    margin-left: 2%;
}
.news-bangle{
    width: 300px;
    height: 300px;
    padding: 20px;
    transition: width 2s;
}
.news-bangle:hover{
    width: 350px;
}
.pearl{
    width: 300px;
    height: 300px;
    padding: 20px;
    transition: width 2s;
}
.pearl:hover{
    width: 350px;
}
.dneck{
    width: 300px;
    height: 300px;
    padding: 20px;
    transition: width 2s;
}
.dneck:hover{
    width: 350px;
}
@media (max-width: 768px) {
    .news{
        margin-top: 40%;
    }
}
