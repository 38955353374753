.LoginSignUpContainer {
  width: 100vw;
  height: 130vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7e7e7;

  top: 0;
  left: 0;
  overflow-y: scroll;
  
}
.box{
  margin-bottom: 10px;
}

.LoginSignUpBox {
  background-color: #ebe4de;
  width: 30vw;
  height: 85vh;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(155,154,148);
  transition: transform 0.3s ease-in-out;
}

.login_signUp_toggle {
  display: flex;
  height: 3vmax;
  perspective: 1000px;
}

.login_signUp_toggle > p {
  color: rgba(0, 0, 0, 0.678);
  font: 300 1vmax "Roboto";
  transition: all 0.5s;
  cursor: pointer;
  display: grid;
  place-items: center;
  width: 100%;
  border-radius: 10px;
  margin: 5px;
  background: #e7e7e7;
  box-shadow: 0 10px 20px rgba(155,154,148);
  transform: translateZ(0);
}

.login_signUp_toggle > p:hover {
  box-shadow: 0 20px 25px rgba(155,154,148);
  transform: translateZ(20px);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}


.LoginSignUpBox > div > button {
  background-color: rgb(155,154,148);
  height: 3px;
  width: 50%;
  border: none;
  transition: all 0.5s;
}

.loginForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2vmax;
  justify-content: space-evenly;
  height: 70%;
  transition: all 0.5s;
}
.signUpForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2vmax;
  justify-content: space-evenly;
  height: 70%;
  transition: all 0.5s;
}

.signUpForm {
  transform: translateY(-100%) translateX(-100vmax);
}

.loginForm > div,
.signUpForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}
.loginForm > div > input,
.signUpForm > div > input {
  padding: 1.5vmax 4vmax;
  padding-right: 1.5vmax;
  width: 90%;
  margin: 10px;
  box-sizing: border-box;
  border: none;
  border-radius: 20px;
  font: 300 1.1vmax "Roboto", sans-serif;
  outline: none;
  background-color: #f0f0f0;
  box-shadow: inset 8px 8px 15px #cbcbcb, 
              inset -8px -8px 15px #ffffff;
  transition: box-shadow 0.3s ease-in-out;
}

.loginForm > div > input:focus,
.signUpForm > div > input:focus {
  background-color: #e9e9e9;
  box-shadow: inset 4px 4px 10px #b8b8b8, 
              inset -4px -4px 10px #ffffff;
              border: rgb(155,154,148) solid 3px;
}


.loginForm > div > svg,
.signUpForm > div > svg {
  position: absolute;
  transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

.loginForm > a {
  color: rgba(0, 0, 0, 0.651);
  text-decoration: none;
  align-self: flex-end;
  transition: all 0.5s;
  font: 500 0.8vmax "Gill Sans";
}

.loginForm > a:hover {
  color: black;
}

#registerImage > img {
  width: 3vmax;
  border-radius: 100%;
}
#registerImage > input {
  display: flex;
  padding: 0%;
}

#registerImage > input::file-selector-button {
  cursor: pointer;
  width: 100%;
  z-index: 2;
  height: 5vh;
  border: none;
  margin: 0%;
  font: 400 0.8vmax ;
  transition: all 0.5s;
  padding: 0 1vmax;
  color: rgba(0, 0, 0, 0.623);
  background-color: rgb(255, 255, 255);
}

#registerImage > input::file-selector-button:hover {
  background-color: rgb(235, 235, 235);
}

.loginBtn,
.signUpBtn {
  border: none;
  background-color: #000000;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  width: 100%;
  padding: 12px 0;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  outline: none;
}

.loginBtn:hover,
.signUpBtn:hover {
  background-color: #524f40;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.shiftToLeft,
.shiftToNeutral,
.shiftToNeutralForm,
.shiftToRight {
  transition: transform 0.3s ease;
}

.shiftToLeft {
  transform: translateX(-100%);
}

.shiftToNeutral {
  transform: translateX(0);
}

.shiftToNeutralForm {
  transform: translateX(0) translateY(-100%);
}

.shiftToRight {
  transform: translateX(100%);
}

@media (max-width: 768px) {
  .loginBtn, .signUpBtn {
    padding: 10px 0;
  }
}

@media (max-width: 480px) {
  .loginBtn, .signUpBtn {
    font-size: 0.9rem;
  }
}


@media screen and (max-width: 600px) {
  .LoginSignUpContainer {
    background-color: white;
  }
  .LoginSignUpBox {
    margin-top: 50px;
    width: 100vw;
    height: 95vh;
  }
  .login_signUp_toggle {
    height: 5vmax;
  }
  .login_signUp_toggle > p {
    font: 300 1.5vmax "Roboto";
  }

  .loginForm
  {
    padding: 5vmax;
  }
  .signUpForm {
    padding: 5vmax;
  }

  .loginForm > div > input,
  .signUpForm > div > input {
    padding: 2.5vmax 5vmax;
    font: 300 1.7vmax;
  }

  .loginForm > div > svg,
  .signUpForm > div > svg {
    font-size: 2.8vmax;
  }

  .loginForm > a {
    font: 500 1.8vmax "Gill Sans";
  }

  #registerImage > img {
    width: 8vmax;
    border-radius: 100%;
  }

  #registerImage > input::file-selector-button {
    height: 7vh;
    font: 400 1.8vmax;
  }

  .loginBtn,
  .signUpBtn {
    font: 300 1.9vmax "Roboto";
    padding: 1.8vmax;
  }
}

.instruct{
  color: #b3422e;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
}