.wishlist-page {
  padding: 20px;
  margin-top: 120px;
}

.wishlist-item-remove {
  background: transparent;
  border: none;
  margin: 0; /* Adjust margin as needed */
  display: block;
  font-size: 16px; /* Adjust font size as needed */
  outline: none;
  position: absolute;
  top: 90px; /* Position it below the move to cart button */
  right: 10px; /* Position it 10px from the right */
  transition: 0.3s;
  padding: 10px; /* the trick */
  color:black; /* Link color */
  text-decoration: none;
  cursor: pointer;
}

.wishlist-item-remove:before {
  content: '';
  border-bottom: 1px solid black;
  padding: 0 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  transition: 0.3s;
}

.wishlist-item-remove:hover:before {
  width: 100%;
  padding: 0; /* the other part of the trick */
}

.wishlist-item-remove:active {
  background: black;
  color: white;
  transition: none;
}

/* General styles */
.wishlist-page {
  padding: 80px;
  border-radius: 50px;
  margin-top: 120px;
}

.wishlist-page-title {
  font-size: 28px;
  color: black;
 /* border: #330 solid;*/
  width: 30%;
  margin: 0 auto;
  /*background-color: rgb(202, 197, 197);*/
  text-align: center;
  padding: 10px 20px;
  text-decoration: underline;
}

.wishlist-items {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  width: 100%; /* Make the container take full width */
  margin-bottom: 10px;
}

.wishlist-item {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 35px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%; /* Make items take full width of the grid column */
  height: auto; /* Adjust height to fit content */
  position: relative; /* Set position relative for absolute positioning of buttons */
}

.wishlist-item-image img {
  width: 25%;
  height: 50%;
}

.wishlist-item-name {
  font-size: 20px;
  color: #333; /* Dark gray color */
  text-decoration: none;
  display: block;
  margin-top: 10px;
}

.gobtn {
  position: absolute; /* Position the button absolutely */
  top: 40px; /* Position it 10px from the top */
  right: 10px; /* Position it 10px from the right */
  background-color: #080100;
  font-size: large;
  border: none;
  color: white;
  padding: 10px 20px; /* Adjust padding for smaller size */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin: 0; /* Remove all margins */
}

.gobtn:hover {
  background-color: #333;
}

.link-back-to-shopping {
  color: #333; /* Dark gray color */
  text-decoration: underline;
}

.cartHeader {
  margin-top: 10px;
  background-color: #9B9A94;
  font-size: 24px;
}

/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .wishlist-page {
    padding: 10px;
    margin-top: 30px;
    width: 100%;
    height: auto;
  }

  .wishlist-page-title {
    margin-top: 28%; 
    font-size: 24px;
    width: 80%;
    padding: 5px 10px;
  }

  .banner {
    width: 100%; /* Make the banner fit the screen width */
    height: auto; /* Allow the height to adjust based on content */
    max-height: 200px; /* Set a max height to prevent it from taking up too much space */
    object-fit: cover; /* Make sure the image is scaled properly */
  }

  .wishlist-items {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .wishlist-item {
    padding: 15px;
    width: 100%;
    position: relative;
    height: auto;
  }

  .wishlist-item-name {
    font-size: 18px;
    margin-bottom: 10px;
    word-wrap: break-word;
  }

  .wishlist-item-image img {
    width: 30%;
    height: auto;
    margin-left: 1%;
    margin-bottom: 10px;
  }

  .gobtn, .wishlist-item-remove {
    font-size: 12px;
    padding: 8px 10px;
  }

  .gobtn {
    top: 60px;
    right: 10px;
  }

  .wishlist-item-remove {
    top: 120px;
    right: 10px;
  }
}
