.bride{
    margin-left: 1%;
    margin-top: 10%;
    font-family: Istok Web, sans-serif;
}
.bride-img{
    margin-left: 15%;
    margin-top: 1%;
    width: 70%;
}
@media (max-width: 768px) {
    .bride{
        margin-top: 35%;
    }
    .bride-text {
      font-size: 14px;
    }
  }