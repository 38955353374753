.paragraph-page {
  margin-top: 10%;
  margin-left: 3%;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.paragraph-page h1 {
  color: #0f0f0f;
}

.paragraph-page p {
  color: black;
}

@media screen and (max-width: 600px) {
  .paragraph-page h1 {
    margin-top: 33%; /* Adjust this value to control the position of the heading */
  }
}
