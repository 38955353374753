.office{
    margin-top: 10%;
    margin-left: 1%;
    display: block;
    unicode-bidi: isolate;
}

.office-img{
    margin-left: 25%;
    width: 50%;
}
.office {
    text-align: justify;
    padding: 20px; /* Optional: Adds padding for better readability */
    line-height: 1.6; /* Optional: Adjusts the space between lines */
  }
  
  .office b {
    text-align: left; /* Keeps the bold text aligned to the left */
  }
  

  @media (max-width: 600px) {
    .office {
      margin-top: 35%;
        margin-left: 1%; /* Remove left margin for mobile */
        padding: 10px; /* Reduce padding for mobile */
        line-height: 1.4; /* Adjust line height for mobile */
        text-align: left; /* Align text to left for mobile */
    }

    .office-img {
        margin-left: 0; /* Remove left margin for mobile */
        width: 100%; /* Make image full width for mobile */
        display: block; /* Ensures image is displayed as block element */
        margin-bottom: 10px; /* Optional: Adds space below the image */
    }

    .office b {
        text-align: left; /* Keeps the bold text aligned to the left */
    }
}