.productReviewsContainer {
  margin-top: 10%;
  width: 90%;
  box-sizing: border-box;
  border-left: 1px solid rgba(0, 0, 0, 0.158);
  height: 110vh;
}

.productReviewsForm {
  width: 23rem;
  margin-left: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3vmax;
  border-color: grey;
}

.productReviewsFormHeading {
  color: rgba(0, 0, 0, 0.733);
  font: 300 2rem "Roboto";
  text-align: center;
}

.productReviewsForm > div {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 2rem;
  position: relative; /* Ensure proper positioning of elements */
}

.productReviewsForm > div > input {
  padding: 1vmax 4vmax;
  padding-right: 3.5vmax; /* Adjust padding to accommodate the icon */
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  font: 300 0.9vmax cursive;
  outline: none;
}

.productReviewsForm > div > svg {
  position: absolute;
  right: 1vmax; /* Move the icon to the right inside the input */
  transform: translateX(0);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
  pointer-events: none; /* Prevent interaction with the icon */
}

@media screen and (max-width: 600px) {
  .productReviewsContainer {
    margin-top: 5%;
    width: 100%; /* Full width for mobile */
    border-left: none;
    border-top: 1px solid rgba(0, 0, 0, 0.158);
    height: auto; /* Adjust height to auto for better responsiveness */
  }

  .productReviewsForm {
    width: 100%; /* Adjust form width for mobile view */
    margin-left: 0; /* Center the form on mobile */
    padding: 2vmax 1vmax; /* Reduce padding to fit content better */
  }

  .productReviewsFormHeading {
    font-size: 1.5rem; /* Adjust font size for mobile */
    margin-bottom: 1.5rem; /* Add some margin below the heading */
  }

  .productReviewsForm > div {
    margin: 1rem 0; /* Reduce margin between elements */
    width: 100%; /* Ensure the divs take up full width */
    position: relative; /* Ensure proper positioning of elements */
  }

  .productReviewsForm > div > input {
    padding: 0.8vmax 2vmax; /* Adjusted padding to prevent overlap */
    padding-right: 3.5vmax; /* Ensure space for the icon */
    font: 300 1vmax "Roboto"; /* Adjusted font size */
    width: 100%; /* Set width to 100% of the container */
  }

  .productReviewsForm > div > svg {
    font-size: 1.4vmax; /* Further reduced the size of the icon */
    left: 0.5vmax; /* Keep the icon on the right */
    transform: translateX(0);
  }
}
